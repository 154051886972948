import React from 'react'
import { Col, Container, Image, Row } from 'react-bootstrap'
import Arrowleft from '../../Assets/images/miami-imgs/arrow-left.png'
import ArrowRight from '../../Assets/images/miami-imgs/arrow-right.png'
import b1 from '../../Assets/images/miami-imgs/best-seller/b1.jpg'
import b2 from '../../Assets/images/miami-imgs/best-seller/b2.jpg'
import b3 from '../../Assets/images/miami-imgs/best-seller/b3.jpg'
import b4 from '../../Assets/images/miami-imgs/best-seller/b4.jpg'
import b5 from '../../Assets/images/miami-imgs/best-seller/b5.jpg'
import b6 from '../../Assets/images/miami-imgs/best-seller/b6.jpg'
import Slider from 'react-slick'
import { FaRegHeart } from "react-icons/fa";


const MiBestSeller = () => {
  const settingsBestSeller = {
    dots: false,
    infinite: true,
    autoplay: true,
    arrows:true,
    prevArrow:<img src={Arrowleft}/>,
    nextArrow:<img src={ArrowRight}/>,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 3,
            infinite: true,
            dots: true
          }
        },
        {
          breakpoint: 992,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 2,
            initialSlide: 2
          }
        },
        {
          breakpoint: 600,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 2,
            initialSlide: 2
          }
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1
          }
        }
      ]
  };
  const bestData = [
    {
        bsImg:b1 ,
        bsPrice:"$360",
        bsText:"14K White Gold Solitaire Cushion Shape Diamond Engagement Ring" ,
        bsUrl:"#",
    },
    {
        bsImg:b2 ,
        bsPrice:"$639",
        bsText:"14K White Gold Solitaire Princess Shape Diamond Engagement Ring" ,
        bsUrl:"#",
    },
    {
        bsImg:b3 ,
        bsPrice:"$492",
        bsText:"14K White Gold Solitaire Emerald Shape Diamond Engagement Ring" ,
        bsUrl:"#",
    },
    {
        bsImg:b4 ,
        bsPrice:"$489",
        bsText:"14K White Gold Solitaire Oval Shape Diamond Engagement Ring" ,
        bsUrl:"#",
    },
    {
        bsImg:b5 ,
        bsPrice:"$420",
        bsText:"14K White Gold Solitaire Marquise Shape Diamond Engagement Ring" ,
        bsUrl:"#",
    },
    {
        bsImg:b6 ,
        bsPrice:"$441",
        bsText:"14K White Gold Solitaire Pear Shape Diamond Engagement Ring" ,
        bsUrl:"#",
    },
 
  ]

  return (
    <>
      <div className='mi_best_seller_section'>
<Container fluid>
    <Row>
        <Col sm={12} md={4} lg={4} className='d-flex align-items-center'>
<div className='mi_best_seller_heading'>
<h2 className='mi_heading'>Best Selling Products</h2>
<p>Discover our top-selling lab-grown diamond jewelry, where luxury meets sustainability. Handpicked for quality and brilliance, these pieces are crafted to dazzle and delight.</p>
</div>
        </Col>
        <Col sm={12} md={8} lg={8}>
                   <Slider {...settingsBestSeller} className='mi_bestSeller_slider'>
                        {bestData.map((val)=>(
                          <div className='mi_best_slider_img_box_outer'>
                           <div className='mi_best_slider_img_box'>
                             <Image src={val.bsImg} className="mi_client_slider_img" alt={ "Best seller Image"}/>
                             <p>{val.bsText}</p>
                             <p className='price'>{val.bsPrice}</p>
                             <div className='heart_iconn_outer'>
                             <div className='heart_iconn'>
                             <FaRegHeart />
                             </div>
                             </div>
                             
                        </div>
                          </div>
                          
                        ))}
                        </Slider>
        </Col>
    </Row>
</Container>
      </div>
    </>
  )
}

export default MiBestSeller
