import { Breadcrumbs, Link, Typography } from '@mui/material';
import React from 'react'
import { Container, Col, Row } from 'react-bootstrap';
import { Helmet } from 'react-helmet';
import { NavLink } from 'react-router-dom';
import "../../../Assets/css/mjstatic.css"
import { useSelector } from 'react-redux';
import { Address_details, Email_details, Je_welryspelling, client_name } from '../../../Helpers/request';

const CookiePolicy = () => {
    const Email_details_link = Email_details.link
    const metaDetails = useSelector(state => state.persistedReducer.meta.meta)
    return (
        <div>
            <Helmet>
                <meta charSet="utf-8" />
                <title>{metaDetails.title}</title>
                <meta name="description" content={metaDetails.description}></meta>
                <meta name="keywords" content={metaDetails.keywords}></meta>
            </Helmet>
            <div className="rcs_ringsetting_section mt-3">
                <Container>
                    <Row>
                        <Col className="rcs_breadcrumb m-0 mb-md-2">
                            <Breadcrumbs aria-label="breadcrumb">
                                <NavLink underline="hover" color="inherit" to="/">
                                    Home
                                </NavLink>
                                <Typography color="text.primary">Cookie  Policy</Typography>
                            </Breadcrumbs>
                        </Col>
                    </Row>
                    <Row>
                        <Col sm={12}>
                            <div className="rcs_privacy_content mt-5 mb-5">
                                <h1>Cookie Policy</h1>
                                {/* <p> <NavLink onClick={(e) => { window.location.href = Email_details.link }} to="#">This is the Cookie Policy for Belgium Webnet, accessible from {Email_details.text}</NavLink></p> */}
                                <h2 className='mt-5'>What Are Cookies</h2>
                                <p>As is common practice with almost all professional websites, this site uses cookies, which are tiny files that are downloaded to your computer, to improve your experience. This page describes what information they gather, how we use it and why we sometimes need to store these cookies. We will also share how you can prevent these cookies from being stored, however this may downgrade or 'break' certain elements of the site's functionality.</p>
                                <h2>How We Use Cookies</h2>
                                <p>We use cookies for a variety of reasons detailed below. Unfortunately, in most cases, there are no industry standard options for disabling cookies without completely disabling the functionality and features they add to this site. It is recommended that you leave on all cookies if you are not sure whether you need them or not in case they are used to provide a service that you use.</p>
                                <h2>Disabling Cookies</h2>
                                <p>You can prevent the setting of cookies by adjusting the settings on your browser (see your browser Help for how doing this). Be aware that disabling cookies will affect the functionality of this and many other websites that you visit. Disabling cookies will usually result in disabling certain functionality and features of the site. Therefore, it is recommended that you do not disable cookies.</p>
                                <h2>The Cookies We Set</h2>
                               
                                <p><strong>Account related cookies</strong><br></br>If you create an account with us then we will use cookies for the management of the SignUp process and general administration. These cookies will usually be deleted when you log out, however in some cases they may remain afterward to remember your site preferences when logged out.</p>
                                <p><strong>Login related cookies</strong><br></br>We use cookies when you are logged in, so that we can remember this fact. This prevents you from having to log in every single time you visit a new page. These cookies are typically removed or cleared when you log out to ensure that you can only access restricted features and areas when logged in.</p>
                                <p><strong>Email newsletters related cookies</strong><br></br>This site offers newsletter or email subscription services, and cookies that may be used to remember if you are already registered and whether to show certain notifications which might only be valid to subscribe/unsubscribed users.</p>
                                <p><strong>Orders processing related cookies</strong><br></br>This site offers e-commerce or payment facilities and some cookies are essential to ensure that your order is remembered between pages so that we can process it properly.</p>
                                <p><strong>Surveys related cookies</strong><br></br>From time to time we offer user surveys and questionnaires to provide you with interesting insights, helpful tools, or to understand our user base more accurately. These surveys may use cookies to remember who has already taken part in a survey or to provide you with accurate results after you change pages.</p>
                                <p><strong>Forms related cookies</strong><br></br>When you submit data to you through a form such as those found on contact pages or comment forms, cookies may be set to remember your user details for future correspondence.</p>
                                <p><strong>Site preferences cookies</strong><br></br>To provide you with a great experience on this site, we provide the functionality to set your preferences for how this site runs when you use it. To remember your preferences, we need to set up cookies so that this information can be called whenever you interact with a page that is affected by your preferences.</p>
                                 <h2>Third Party Cookies</h2>
                                 <p>In some cases, we also use cookies provided by trusted third parties. The following section details which third party cookies, you might encounter through this site.</p>
                                <p>This site uses Google Analytics, which is one of the most widespread and trusted analytics solutions on the web for helping us to understand how you use the site and ways that we can improve your experience. These cookies may track things such as how long you spend on the site and the pages that you visit so we can continue to produce engaging content.</p>
                               <p>For more information on Google Analytics cookies, see the official Google Analytics page.
From time to time we test new features and make subtle changes to the way that the site is delivered. While we are still testing new features these cookies may be used to ensure that</p>
<p>For more information on Google Analytics cookies, see the official Google Analytics page.
</p>

                                <h2>More Information</h2>
                                <p>Hopefully, that has clarified things for you and as was previously mentioned, if there is something that you aren't sure whether you need or not it's usually safe to leave cookies enabled in case it does interact with one of the features you use on our site.</p>
                                <p>However, if you are still looking for more information then you can contact us through one of our preferred contact methods:</p>
                                <p>{client_name.c_name} is based in the U.S. and the information we collect is governed by U.S. law. Your information may be stored and processed in the United States or any other country in which {client_name.c_name} or its partners, affiliates, service providers, subsidiaries or agents maintain facilities and by using this Website, you consent to any such transfer of information outside of your country. Data protection laws in the U.S. and other jurisdictions may be different from those of your country of residence. Your use of the Service or provision of any information therefore constitutes your consent to the transfer to and from, processing, usage, sharing, and storage of your information in the U.S. and other jurisdictions as set forth in this Privacy Policy. If your data is collected in Europe, we will transfer your personal data subject to appropriate or suitable safeguards.</p>
                                <h2>Contact Us</h2>
                                <p>If you have questions about this cookie policy, or the information practices, please contact us as follows:</p>
                                <p><strong>By Email:</strong> <NavLink onClick={(e) => { window.location.href = Email_details.link }} to="#">{Email_details.text}</NavLink></p>
                                <p><strong>By Address:</strong> <span>
                                    <li onClick={() => { window.open(Address_details.link, '_blank') }}>
                                        {Address_details.textLine1},{" "}
                                        {Address_details.textLine2}
                                    </li>
                                </span></p>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>
        </div>
    )
}

export default CookiePolicy;