import React, { useEffect } from "react";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Helmet } from "react-helmet";
import AOS from "aos";
import "aos/dist/aos.css";
import { useSelector } from "react-redux";
import { client_name } from "../../../Helpers/request";
import BannerSlider from "./Components/Bannerslider";
import ShopByDiamondShape from "./Components/ShopByDiamondShape";
import ExploreCollection from "./Components/ExploreCollection";
import DesignDiamondRing from "./Components/DesignDiamondRing";
import ShopRingByStyle from "./Components/ShopRingByStyle";
import GemstoneGlamour from "./Components/GemstoneGlamour";
import CustomDesign from "./Components/CustomDesign";
import ConsultSpecialist from "./Components/ConsultSpecialist";
import Customer from "./Components/Customer";
import Insta from "./Components/insta";
import SignupTo from "./Components/SignupTo";
import MiShopDiamondsByShap from "../../Front/MiShopDiamondsByShap";
import MiDiamondEngRing from "../../Front/MiDiamondEngRing";
import MiBestSeller from "../../Front/MiBestSeller";
import MiWeddingRingFineJewelry from "../../Front/MiWeddingRingFineJewelry";
import MiCustomDesign from "../../Front/MiCustomDesign";
import MiClintSlider from "../../Front/MiClintSlider";
import MiCustomer from "../../Front/MiCustomer";
import MiCollectionAccordian from "../../Front/MiCollectionAccordian";
import { Container } from "react-bootstrap";

const Home = () => {
  const metaDetails = useSelector((state) => state.persistedReducer.meta.meta);

  useEffect(() => {
    AOS.init({
      duration: 1000,
      // disable: 'mobile'
    });
  }, []);

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{metaDetails?.title}</title>
        <meta name="description" content={metaDetails?.description}></meta>
        <meta name="keywords" content={metaDetails?.keywords}></meta>
        {/*Og Tags */}
        <meta property="og:locale" content="en_US" />
        <meta property="og:type" content="website" />
        <meta property="og:title" content={metaDetails?.title} />
        <meta property="og:description" content={metaDetails?.description} />
        <meta property="og:url" content={metaDetails?.url} />
        <meta property="og:site_name" content={client_name?.c_name} />
      </Helmet>
      <BannerSlider />
<Container className="mi_custom_home_container p-0">
      <div className="LandingPageShocase">
        {/* <BannerSlider /> */}
     
      <MiShopDiamondsByShap/>
      <MiDiamondEngRing/>
      <MiBestSeller/>
      <MiWeddingRingFineJewelry/>
      <MiCustomDesign/>
      {/* <MiClintSlider/> */}
      {/* <MiCustomer/> */}


        {/* <ShopByDiamondShape />
        <ExploreCollection />
        <DesignDiamondRing />
        <ShopRingByStyle />
        <GemstoneGlamour />
        <CustomDesign />
        <ConsultSpecialist />
        <Customer /> */}
        {/* <Insta /> */}
        <SignupTo />
        {/* <MiCollectionAccordian/> */}
      </div>
      </Container>
    </>
  );
};

export default Home;
