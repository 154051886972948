import React from 'react'
import { Col, Container, Image, Row } from 'react-bootstrap'
import customImg1 from '../../Assets/images/miami-imgs/custom-design/c1.jpg'
import customImg2 from '../../Assets/images/miami-imgs/custom-design/c2.jpg'
import customImg3 from '../../Assets/images/miami-imgs/custom-design/c3.jpg'
import { useHistory } from 'react-router-dom'


const MiCustomDesign = () => {
    const history = useHistory();
    const customeData = [
    {
        cName:"Diamond",
        cText:"From modern to vintage inspired designs, our extensive collection is sure to hold a ring that meets your style and budget.",
        cImg:customImg1,
         cUrl:"/diamonds",
    },
    {
        cName:"Custom Design",
        cText:"Our designers use your ideas, drawings, and images to visualize your exclusive design concept.",
        cImg:customImg2,
         cUrl:"/custom-design",
    },
    {
        cName:"Delivery",
        cText:"Once you’ve chosen your setting and diamond, you’ll be able to review your completed ring. You can even add engraving to personalize your choice further.",
        cImg:customImg3,
         cUrl:"/jewelry/wedding-rings",
    },
    ]
  return (
    <>
      <div className='mi_custome_Design_section'>
<Container fluid>
    <Row>
        <Col sm={12} md={12} lg={12}>
            <div className='custome_heading_box text-center'>
<h2 className='mi_heading'>Custom Design</h2>
<p className='text-center'>Create a beautiful, one-of-a-kind representation of your unique love. </p>
            </div>
        </Col>
    </Row>
    <Row>
       {customeData.map((val)=>(
        <Col sm={12} md={4} lg={4}>
            <div className='mi_custom_text_img_box'>
                <Image src={val.cImg} className='img-fluid' alt={val.cName + "Image"}/>
                <h3 onClick={()=> history.push(val.cUrl)}>{val.cName}</h3>
                <p>{val.cText}</p>
            </div>
        </Col>
       ))}
    </Row>
</Container>
      </div>
    </>
  )
}

export default MiCustomDesign
